import { SnackbarProvider } from 'notistack';
import { RouterProvider } from 'react-router-dom';
import router from 'router';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

import LoginProvider from 'components/LoginProvider';
import VipProvider from 'components/VipProvider';
import store, { StoreContext } from 'store';
import theme from 'theme';

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      <SnackbarProvider>
        <StoreContext.Provider value={store}>
          <LoginProvider>
            <VipProvider>
              <RouterProvider router={router} />
            </VipProvider>
          </LoginProvider>
        </StoreContext.Provider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}
