const UA = window.navigator.userAgent.toLocaleLowerCase();

export const isWeChat = UA.match(/MicroMessenger/i)?.toString() === 'micromessenger';

export const isIPad = UA.match(/ipad/i)?.toString() === 'ipad' && !window.MSStream;

export const isIPhone = UA.match(/iphone os/i)?.toString() === 'iphone os' && !window.MSStream;

export const isIOS = isIPad || isIPhone;

export const isAndroid = UA.match(/android/i)?.toString() === 'android';

export const isWindowsCE = UA.match(/windows ce/i)?.toString() === 'windows ce';

export const isWindowsMobile = UA.match(/windows mobile/i)?.toString() === 'windows mobile';

export const isMobile = !!(isIPad || isIPhone || isAndroid || isWindowsCE || isWindowsMobile);

export const isPC = !isMobile;

const device = {
  isWeChat,
  isIPad,
  isIPhone,
  isIOS,
  isAndroid,
  isWindowsCE,
  isWindowsMobile,
  isMobile,
  isPC
};

export default device;
