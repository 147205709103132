import merge from 'lodash/merge';
import { FC, useMemo } from 'react';

import Drawer, { DrawerProps } from '@mui/material/Drawer';
import { Theme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { SystemCssProperties } from '@mui/system';

const ReaderDrawer: FC<DrawerProps> = (props) => {
  const { sx = {}, ...other } = props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const style = useMemo(() => {
    const obj = typeof sx === 'function' ? sx(theme) : sx;

    return merge(
      { userSelect: 'none' },
      obj,
      matches ? { width: (obj as SystemCssProperties<Theme>)?.width || 520 } : { maxHeight: '70vh', width: '100%' }
    );
  }, [matches, sx, theme]);

  return <Drawer anchor={matches ? 'right' : 'bottom'} elevation={1} {...other} PaperProps={{ sx: style }} />;
};

export default ReaderDrawer;
