import { isAndroid, isIOS } from './device';

export function openSchema(urlSchema: string, fallbackCallback?: Function) {
  let appOpened = false;
  let iframe: HTMLIFrameElement | null = null;

  function cleanUp() {
    if (iframe && iframe.parentNode) {
      iframe.parentNode.removeChild(iframe);
    }
    document.removeEventListener('visibilitychange', visibilityChangeHandler);
    window.removeEventListener('blur', openSuccess);
  }

  function visibilityChangeHandler() {
    if (document.hidden) {
      openSuccess();
    }
  }

  function openSuccess() {
    clearTimeout(timer);
    appOpened = true;
    cleanUp();
  }
  if (isAndroid || /^http/.test(urlSchema)) {
    window.location.href = urlSchema;
  } else if (isIOS) {
    iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = urlSchema;
    document.body.appendChild(iframe);
  } else {
    fallbackCallback && fallbackCallback();
    return;
  }

  const timer = setTimeout(() => {
    if (!appOpened) {
      cleanUp();
      if (typeof fallbackCallback === 'function') {
        fallbackCallback();
      }
    }
  }, 2000);

  document.addEventListener('visibilitychange', visibilityChangeHandler);
  window.addEventListener('blur', openSuccess);
}
