import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { getWeChatLoginConfirm } from 'api';
import CunJinIcon from 'components/icons/CunJinIcon';
import LoginComputer from 'components/icons/LoginComputer';
import useStore from 'hooks/useStore';
import device from 'utils/device';
import { wechatLogin } from 'utils/wechat';

function Confirm() {
  const store = useStore();
  const [status, setStatus] = useState(0);
  const [searchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const [buttonHtml] = useState(`<wx-open-launch-weapp
  id="launch-btn"
  appid="wxceb094cadd497c81"
  path="pages/home/index"
  style="position:absolute;left:0;top:0;bottom:0;right:0;width:100%;height:100%;overflow:hidden;opacity:0;z-index:100;padding:0;"
>
  <script type="text/wxtag-template">
    <style>.launch-weapp-btn{width:500px;height:500px;display:block;opacity:0;border:0;border-radius:0;}</style>
    <button class="launch-weapp-btn">打开小程序</button>
  </script>
</wx-open-launch-weapp>`);
  const code = useMemo(() => searchParams.get('code'), [searchParams]);

  const updateStatus = function (code: string, confirm: number) {
    return getWeChatLoginConfirm({
      code,
      confirm
    });
  };

  const handleConfirm = useCallback(
    async function () {
      if (!code) return;

      try {
        await updateStatus(code, 2);
        setStatus(1);
      } catch (e) {
        if (e instanceof Error) {
          enqueueSnackbar(e.message, {
            variant: 'error'
          });
        }
      }
    },
    [code, enqueueSnackbar]
  );

  useEffect(() => {
    if (device.isWeChat && code && store.user.isReady) {
      if (store.user.isLogin) {
        updateStatus(code, 1);
      } else {
        wechatLogin();
      }
    }
  }, [code, store.user.isLogin, store.user.isReady]);

  return (
    <Container component={Stack}>
      <Stack sx={{ justifyContent: 'center', alignItems: 'center', height: '70vh', userSelect: 'none' }}>
        <LoginComputer sx={{ display: 'block' }} />
        <Typography
          sx={(theme) => ({
            fontFamily: theme.typography.fontFamilySerif,
            fontWeight: 900,
            fontSize: 28,
            mt: 4.5,
            letterSpacing: 2,
            textIndent: 2
          })}
          color="textPrimary"
        >
          {status ? '登录成功' : '寸进阅读网页版'}
        </Typography>
        {device.isWeChat ? (
          <Typography variant="body1" color="textSecondary" sx={{ mt: 1.5 }}>
            {status ? '你可以在网页上开始阅读了' : '登录确认'}
          </Typography>
        ) : (
          <Typography variant="body1" color="textSecondary" sx={{ mt: 1.5 }}>
            请使用微信扫码登录
          </Typography>
        )}
      </Stack>
      {device.isWeChat && !status && (
        <Button
          variant="contained"
          color="primary"
          size="large"
          sx={{ mt: '10vh', letterSpacing: '1em', textIndent: '1em' }}
          onClick={handleConfirm}
        >
          登录
        </Button>
      )}
      {device.isWeChat && !!status && (
        <Stack
          direction="row"
          sx={(theme) => ({
            bgcolor: theme.palette.background.card,
            alignItems: 'center',
            mt: '10vh',
            p: 2,
            mx: 'auto',
            width: '100%',
            maxWidth: 375,
            position: 'relative',
            borderRadius: 3,
            overflow: 'hidden'
          })}
          spacing={2}
        >
          <CunJinIcon
            sx={{
              width: 40,
              height: 40
            }}
          />
          <Box sx={(theme) => ({ flex: 1, color: theme.palette.mode === 'dark' ? '#ecd8ac' : '#bf8400' })}>
            <Typography
              variant="body2"
              sx={(theme) => ({
                fontFamily: theme.typography.fontFamilySerif,
                fontWeight: 700,
                letterSpacing: 1
              })}
            >
              寸进阅读7天VIP体验卡
            </Typography>
            <Typography
              variant="body2"
              sx={(theme) => ({
                fontFamily: theme.typography.fontFamilySerif,
                fontWeight: 700,
                letterSpacing: 1
              })}
            >
              持续阅读最多可领14天
            </Typography>
          </Box>
          <ButtonBase
            sx={(theme) => ({
              ...theme.typography.button,
              background: 'linear-gradient(to right, #d9bf84, #ead6a9);',
              px: 2,
              py: 0.5,
              color: '#4f3a19',
              borderRadius: '50px'
            })}
          >
            立即体验
          </ButtonBase>
          <Box
            component="div"
            sx={{ position: 'absolute', left: 0, top: 0, bottom: 0, right: 0, margin: '0 !important', cursor: 'pointer' }}
            dangerouslySetInnerHTML={{ __html: buttonHtml }}
          />
        </Stack>
      )}
    </Container>
  );
}

export default observer(Confirm);
