import { FC } from 'react';

import Box from '@mui/material/Box';

import NodeContent from '../NodeContent';
import NodeWrap from '../NodeWrap';
import NodeKeyPoints from '../nodes/NodeKeyPoints';

const Section: FC<{ section: BookChapterSection }> = ({ section }) => {
  return (
    <Box id={`section-${section.id}`}>
      {!!section.title && <h3>{section.title}</h3>}
      {section.nodes.map((node) =>
        node.type !== 'exercise' ? (
          <NodeWrap key={node.id} id={`node-${node.id}`} title={node.title}>
            {(() => {
              switch (node.type) {
                case 'exercise':
                  return;
                case 'keyPoints':
                  return <NodeKeyPoints node={node} />;
                default:
                  return <NodeContent node={node} />;
              }
            })()}
          </NodeWrap>
        ) : undefined
      )}
    </Box>
  );
};

export default Section;
