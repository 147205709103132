import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Container from '@mui/material/Container';
import Drawer from '@mui/material/Drawer';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { getCategoryList } from 'api';

import Books from './components/Books';
import Menu from './components/Menu';
import TitleBar from './components/TitleBar';

function Category() {
  const { slug } = useParams();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const [open, setOpen] = useState(false);
  const [list, setList] = useState<Awaited<ReturnType<typeof getCategoryList>>['data']>([]);
  const categories = useMemo(() => {
    return [
      {
        id: 0,
        parent_id: 0,
        slug: '',
        name: '全部',
        children: []
      },
      ...list
    ];
  }, [list]);
  const category = useMemo(() => {
    const cate = categories.find((item) => {
      if (item.slug === (slug || '')) return true;
      return !!item.children.find((children) => children.slug === slug);
    });

    return cate;
  }, [categories, slug]);

  const getCategory = async () => {
    const res = await getCategoryList();
    setList(res.data);
  };

  useEffect(() => {
    getCategory();
  }, []);

  return (
    <Container>
      {matches && <Menu list={categories} slug={category?.slug || ''} />}
      {!matches && (
        <Drawer anchor="left" elevation={1} open={open} onClose={() => setOpen(false)}>
          <Menu list={categories} slug={category?.slug || ''} inline />
        </Drawer>
      )}
      <Box sx={{ pl: { md: '160px' } }}>
        <TitleBar category={category} slug={slug} />
        <Books slug={slug} />
      </Box>
      <ButtonBase
        sx={{
          position: 'fixed',
          bottom: 24,
          right: 16,
          borderRadius: '100%',
          width: 48,
          height: 48,
          mb: 3,
          bgcolor: theme.palette.background.card,
          color: theme.palette.text.secondary,
          display: {
            xs: 'inline-flex',
            md: 'none'
          }
        }}
        onClick={() => setOpen(true)}
      >
        <MenuIcon />
      </ButtonBase>
    </Container>
  );
}

export default Category;
