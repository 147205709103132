import { FC } from 'react';

import Box from '@mui/material/Box';
import { buttonBaseClasses } from '@mui/material/ButtonBase';
import { svgIconClasses } from '@mui/material/SvgIcon';

import ToolBarButtonFont from '../ToolBarButtonFont';
import ToolBarButtonMark from '../ToolBarButtonMark';
import ToolBarButtonMenu from '../ToolBarButtonMenu';
import ToolBarButtonTheme from '../ToolBarButtonTheme';

const ToolBar: FC = () => {
  return (
    <Box
      sx={(theme) => ({
        [`.${svgIconClasses.root}`]: { verticalAlign: 'top' },

        [theme.breakpoints.down('md')]: {
          position: 'fixed',
          bottom: 0,
          left: 0,
          width: '100%',
          bgcolor: theme.palette.background.card,
          display: 'flex',
          borderTop: `0.5px solid ${theme.palette.divider}`,

          [`.${buttonBaseClasses.root}`]: {
            flex: 1,
            py: 1.5,
            '&:first-of-type': {
              borderLeft: 'none'
            }
          }
        },
        [theme.breakpoints.up('md')]: {
          position: 'fixed',
          bottom: 24,
          left: '90%',
          ml: 3,
          width: 48,
          [`.${buttonBaseClasses.root}`]: {
            borderRadius: '100%',
            width: 48,
            height: 48,
            mb: 3,
            color: theme.palette.text.secondary,
            bgcolor: theme.palette.background.card
          }
        }
      })}
    >
      <ToolBarButtonMenu />
      <ToolBarButtonFont />
      <ToolBarButtonMark />
      <ToolBarButtonTheme />
    </Box>
  );
};

export default ToolBar;
