import { FC, PropsWithChildren } from 'react';

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

const NodeWrap: FC<PropsWithChildren<{ title?: string; id?: string | number; skeleton?: boolean }>> = ({
  title,
  children,
  id,
  skeleton
}) => {
  return (
    <Box id={`${id || ''}`} sx={{ m: { xs: 3, sm: 6, md: 12.5 } }}>
      {skeleton ? (
        <h2>
          <Skeleton variant="text" sx={{ width: '40%', margin: '0 auto' }} />
        </h2>
      ) : (
        !!title && <h2>{title}</h2>
      )}
      {!skeleton ? (
        children
      ) : (
        <>
          <p>
            <Skeleton variant="text" sx={{ width: '100%' }} />
            <Skeleton variant="text" sx={{ width: '100%' }} />
            <Skeleton variant="text" sx={{ width: '100%' }} />
            <Skeleton variant="text" sx={{ width: '100%' }} />
            <Skeleton variant="text" sx={{ width: '100%' }} />
            <Skeleton variant="text" sx={{ width: '60%' }} />
          </p>
        </>
      )}
    </Box>
  );
};

export default NodeWrap;
