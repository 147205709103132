import { WECHAT_APPID } from 'config';
import wx from 'weixin-js-sdk';

import { getJsApiTicket } from 'api';

export function wechatLogin(callback: string = window.location.href, scope: 'snsapi_base' | 'snsapi_userinfo' = 'snsapi_base') {
  return (window.location.href = `${window.location.origin}/api/auth/wechat/offiaccount/access_token?appid=${WECHAT_APPID}&callback=${encodeURIComponent(callback.split('#')[0])}&scope=${scope}`);
}

export async function initWechat() {
  const { data } = await getJsApiTicket({
    appid: WECHAT_APPID,
    url: window.location.href.split('#')[0]
  });

  wx.config({
    ...data,
    jsApiList: [
      'updateAppMessageShareData',
      'updateTimelineShareData',
      'onMenuShareTimeline',
      'onMenuShareAppMessage',
      'onMenuShareQQ',
      'onMenuShareWeibo',
      'onMenuShareQZone',
      'startRecord',
      'stopRecord',
      'onVoiceRecordEnd',
      'playVoice',
      'pauseVoice',
      'stopVoice',
      'onVoicePlayEnd',
      'uploadVoice',
      'downloadVoice',
      'chooseImage',
      'previewImage',
      'uploadImage',
      'downloadImage',
      'translateVoice',
      'getNetworkType',
      'openLocation',
      'getLocation',
      'hideOptionMenu',
      'showOptionMenu',
      'hideMenuItems',
      'showMenuItems',
      'hideAllNonBaseMenuItem',
      'showAllNonBaseMenuItem',
      'closeWindow',
      'scanQRCode',
      'openProductSpecificView',
      'addCard',
      'chooseCard',
      'openCard'
    ],
    openTagList: ['wx-open-launch-weapp']
  });

  return new Promise<void>((resolve, reject) => {
    wx.ready(resolve);
    wx.error(reject);
  });
}

export function wechatPay(config: WeChatTransactionConfig) {
  return new Promise((resolve, reject) => {
    function pay() {
      WeixinJSBridge.invoke('getBrandWCPayRequest', { ...config, appId: WECHAT_APPID }, function (res: { err_msg: string }) {
        if (res.err_msg === 'get_brand_wcpay_request:ok') {
          return resolve(res);
        }
        reject(res);
      });
    }

    function onBridgeReady() {
      pay();
      document.removeEventListener('WeixinJSBridgeReady', onBridgeReady);
    }

    if (typeof WeixinJSBridge == 'undefined') {
      document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
    } else {
      pay();
    }
  });
}
