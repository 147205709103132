import { FC } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import mpCode from 'assets/images/mp-code.jpeg';

const MpCodeDialog: FC<{ open: boolean; onClose: () => void }> = ({ open, onClose }) => {
  return (
    <Dialog aria-labelledby="mp-code-dialog-title" onClose={onClose} open={open} sx={{ userSelect: 'none' }}>
      <DialogTitle
        id="mp-code-dialog-title"
        sx={{
          pt: 8,
          letterSpacing: '0.05em'
        }}
        fontFamily={(theme) => theme.typography.fontFamilySerif}
        fontWeight={700}
        align="center"
      >
        在手机上使用寸进阅读 · 获得更好的阅读体验
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={(theme) => ({
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
          cursor: 'pointer'
        })}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        sx={{
          width: 640,
          maxWidth: '100%',
          py: 6
        }}
      >
        <Box
          sx={{
            width: 280,
            height: 280,
            userSelect: 'none',
            overflow: 'hidden',
            position: 'relative',
            mx: 'auto',
            p: 2,
            bgcolor: 'white',
            borderRadius: 8,
            img: {
              width: '100%',
              height: '100%',
              display: 'block'
            }
          }}
        >
          <img src={mpCode} alt="微信小程序二维码" draggable="false" />
        </Box>
        <Typography variant="body1" align="center" sx={{ mt: 4 }}>
          请使用微信扫码查看
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default MpCodeDialog;
