import { FC } from 'react';

import ButtonBase, { ButtonBaseProps } from '@mui/material/ButtonBase';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const ToolBarButton: FC<ButtonBaseProps> = (props) => {
  const { title, children, ...otherProps } = props;
  const theme = useTheme();
  const matchedMd = useMediaQuery(theme.breakpoints.up('md'));
  const matcheSm = useMediaQuery(theme.breakpoints.up('sm'));

  if (matchedMd) {
    return (
      <Tooltip arrow placement="left" title={title}>
        <ButtonBase
          sx={{
            color: theme.palette.text.secondary
          }}
          {...otherProps}
        >
          {children}
        </ButtonBase>
      </Tooltip>
    );
  }
  if (matcheSm) {
    return (
      <ButtonBase
        {...otherProps}
        sx={{
          color: theme.palette.text.secondary
        }}
      >
        {children}
        <Typography variant="button" sx={{ ml: 1 }} color="textSecondary">
          {title}
        </Typography>
      </ButtonBase>
    );
  }
  return (
    <ButtonBase
      sx={{
        color: theme.palette.text.secondary
      }}
      {...otherProps}
    >
      {children}
    </ButtonBase>
  );
};

export default ToolBarButton;
