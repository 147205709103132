import { createContext } from 'react';

export interface VipContextStore {
  handleShowVip: () => void;
}

const VipContext = createContext<VipContextStore>({
  handleShowVip() {}
});

export default VipContext;
