import { createContext } from 'react';

export interface LoginContextStore {
  handleLogin: () => void;
  handleOpenApp: () => void;
}

const LoginContext = createContext<LoginContextStore>({
  handleLogin() {},
  handleOpenApp() {}
});

export default LoginContext;
