import { createGet, createPost } from 'utils/http';

export const getUserInfo = createGet('/account/userinfo');

export const getJsApiTicket = createGet('/wechat/offiaccount/jsapi_ticket');

export const getVipList = createGet('/vip/product/list');

export const getVipDetail = createGet('/vip/product/detail');

export const transactionWechatOrder = createPost('/pay/wechat/vip/transactions');

export const getHomeBooks = createGet('/home/books');

export const getHomeRecommend = createGet('/home/recommend');

export const getHomeCollections = createGet('/home/collections');

export const getCategoryList = createGet('/category/list');

export const getParentCategory = createGet('/category/parent');

export const getBookList = createGet('/book/list');

export const getBookDetail = createGet('/book/detail');

export const getBookChapterList = createGet('/book/chapter/list');

export const getBookChapterDetail = createGet('/book/chapter/detail');

export const getBookCelebrityRecommend = createGet('/celebrity/withbook');

export const getWeChatLoginCode = createPost('/wechat/offiaccount/login/qrcode/generate');

export const getWeChatLoginStatus = createPost('/wechat/offiaccount/login/qrcode/status');

export const getWeChatLoginConfirm = createPost('/wechat/offiaccount/login/qrcode/confirm');

export const updateBookLike = createPost('/book/user/like/update');

export const getMpSchema = createPost('/wechat/mp/schema');
