import { Components, Theme } from '@mui/material/styles';

const MuiCssBaseline: Components<Theme>['MuiCssBaseline'] = {
  styleOverrides: (theme) => ({
    html: { scrollbarWidth: 'thin' },
    body: {
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : '#f4f5f7'
    }
  })
};

export default MuiCssBaseline;
