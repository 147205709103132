import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import Box from '@mui/system/Box';

import { getParentCategory } from 'api';
import TitleBar from 'components/TitleBar';

import CategoryItem from '../CategoryItem';

function Category() {
  const [list, setList] = useState<Awaited<ReturnType<typeof getParentCategory>>['data']>([]);
  const [loading, setLoading] = useState(true);
  const getList = async () => {
    setLoading(true);
    try {
      const res = await getParentCategory();
      if (res.data.length > 8) {
        res.data.length = 8;
      }
      setList(res.data);
    } catch (e) {}
    setLoading(false);
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <Box sx={{ mt: 4 }}>
      <TitleBar title="分类">
        <Button size="small" component={Link} to="/category">
          全部分类
        </Button>
      </TitleBar>
      {!loading && list && (
        <Grid component="ul" container spacing={3} sx={{ listStyle: 'none', p: 0, m: 0 }}>
          {list.map((category) => (
            <CategoryItem key={category.id} category={category} />
          ))}
        </Grid>
      )}
      {loading && (
        <Grid component="ul" container spacing={3} sx={{ listStyle: 'none', p: 0, m: 0 }}>
          {Array(8)
            .fill('1')
            .map((n, idx) => (
              <CategoryItem key={idx} />
            ))}
        </Grid>
      )}
    </Box>
  );
}

export default Category;
