import { FC, PropsWithChildren } from 'react';

import Grid from '@mui/material/Grid2';

const BookCardList: FC<PropsWithChildren> = (props) => {
  return (
    <Grid component="ul" container spacing={3} sx={{ listStyle: 'none', p: 0, m: 0 }}>
      {props.children}
    </Grid>
  );
};

export default BookCardList;
