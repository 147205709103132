import { FC, useContext, useState } from 'react';

import TitleIcon from '@mui/icons-material/Title';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import FontLargeIcon from 'components/icons/FontLarge';
import FontSmallIcon from 'components/icons/FontSmall';

import ReaderContext from '../../ReaderContext';
import ReaderDrawer from '../ReaderDrawer';
import ToolBarButton from '../ToolBarButton';

const ToolBarButtonFont: FC = () => {
  const theme = useTheme();
  const { fontSize, fontFamily, setFontSize, setFontFamily } = useContext(ReaderContext);
  const [open, setOpen] = useState(false);

  return (
    <>
      <ToolBarButton title="文字排版" onClick={() => setOpen(true)}>
        <TitleIcon />
      </ToolBarButton>
      <ReaderDrawer open={open} onClose={() => setOpen(false)} sx={{ p: 4.5, width: 400 }}>
        <Typography variant="subtitle2" sx={{ mb: 2 }}>
          字号大小
        </Typography>

        <Stack direction="row" alignItems="center" spacing={3}>
          <FontSmallIcon />
          <Slider
            aria-label="字号大小"
            value={fontSize}
            shiftStep={10}
            step={2}
            marks={[
              {
                value: 16
              }
            ]}
            valueLabelDisplay="off"
            min={12}
            max={64}
            color="primary"
            onChange={(e, size) => setFontSize(size as number)}
          />
          <FontLargeIcon />
        </Stack>
        <Typography variant="subtitle2" sx={{ mt: 5, mb: 2 }}>
          字体
        </Typography>
        <ToggleButtonGroup
          value={fontFamily}
          size="small"
          color="primary"
          exclusive
          onChange={(e, value) => !!value && setFontFamily(value)}
          aria-label="字体"
          fullWidth
        >
          <ToggleButton
            value={theme.typography.fontFamily as string}
            aria-label="无衬线体"
            sx={{ fontFamily: theme.typography.fontFamily }}
          >
            无衬线体
          </ToggleButton>
          <ToggleButton value={theme.typography.fontFamilySerif} aria-label="衬线体" sx={{ fontFamily: theme.typography.fontFamilySerif }}>
            衬线体
          </ToggleButton>
        </ToggleButtonGroup>
      </ReaderDrawer>
    </>
  );
};

export default ToolBarButtonFont;
