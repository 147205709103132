import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import TitleBar from 'components/TitleBar';

function TextBox(props: { title: string; text: string }) {
  const { title, text } = props;

  return (
    <Box sx={{ my: { xs: 2, sm: 4 } }}>
      <TitleBar title={title} />
      <Typography variant="body1" color="textSecondary" whiteSpace="pre-wrap">
        {text}
      </Typography>
    </Box>
  );
}

export default TextBox;
