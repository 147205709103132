import { FC } from 'react';

import NodeContent from '../../NodeContent';

const NodeKeyPoints: FC<{ node: BookChapterNode }> = ({ node }) => {
  return (
    <>
      {(node.ext.data as []).map((item: { type: string; title: string; format: string; review: string; content: string }, idx) => (
        <div id={`node-${node.id}-${idx}`} key={`${item.title}${idx}`}>
          <NodeContent node={{ ...(item as unknown as BookChapterNode), ext: { format: item.format } }} />
        </div>
      ))}
    </>
  );
};

export default NodeKeyPoints;
