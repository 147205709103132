import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import QRCode from 'qrcode';
import { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react';

import BlockIcon from '@mui/icons-material/Block';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import NoFlashRoundedIcon from '@mui/icons-material/NoFlashRounded';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { rgbToHex, styled, useTheme } from '@mui/material/styles';

import { getWeChatLoginCode, getWeChatLoginStatus } from 'api';
import MpCodeDialog from 'components/MpCodeDialog';
import useStore from 'hooks/useStore';
import device, { isAndroid, isIOS } from 'utils/device';
import openMp from 'utils/openMp';
import sleep from 'utils/sleep';
import { initWechat, wechatLogin } from 'utils/wechat';

import LoginContext from './LoginContext';

const QrCodeMask = styled(Stack)(() => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  left: 0,
  top: 0,
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1,
  svg: { marginBottom: '16px', width: 56, height: 56 }
}));

const LoginProvider: FC<PropsWithChildren> = (props) => {
  const store = useStore();
  const theme = useTheme();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [openMpCode, setOpenMpCode] = useState(false);
  const [open, setOpen] = useState(false);
  const [code, setCode] = useState('');
  const [qrCode, setQrCode] = useState('');
  const [qrCodeStatus, setQrCodeStatus] = useState(0);
  const [fetchController, setFetchController] = useState<AbortController | null>(null);

  const handleLogin = async () => {
    if (device.isWeChat) {
      wechatLogin(window.location.href, 'snsapi_userinfo');
      return;
    }

    if (isIOS || isAndroid) {
      return handleOpenApp();
    }

    loadQrCode();
    setOpen(true);
  };

  const handleOpenApp = () => {
    const matchedBook = window.location.pathname.match(/\/book\/(\d+)/);

    if (matchedBook && !!matchedBook[1]) {
      // 图书
      openMp('pages-sub-a/detail/index', `id=${matchedBook[1]}`, () => setOpenMpCode(true));
    } else {
      // 首页
      openMp('pages/home/index', '', () => setOpenMpCode(true));
    }
  };

  const handleClose = () => {
    setOpen(false);
    if (fetchController) {
      fetchController.abort();
      setFetchController(null);
    }
  };

  const fetchStatus = useCallback(
    async function (code: string, status?: number): Promise<void> {
      if (!code) return;

      const controller = new AbortController();

      setFetchController(controller);

      const res = await getWeChatLoginStatus({ code, status }, { signal: controller.signal, timeout: 75000 });

      if (!res || res.statusCode !== 0) {
        setQrCodeStatus(4);
        return;
      }

      setQrCodeStatus(res.data.status);

      if (res.data.status === 1) {
        return fetchStatus(code, res.data.status);
      }

      if (res.data.status === 2) {
        await store.user.fetchUserInfo();
        await sleep();
        setOpen(false);
      }
    },
    [store.user]
  );

  const loadQrCode = async () => {
    try {
      closeSnackbar();
      setQrCodeStatus(0);
      setQrCode('');

      const res = await getWeChatLoginCode({ appid: 'wxadcf19e0190d056c' });
      setCode(res.data || '');

      if (fetchController) {
        fetchController.abort();
        setFetchController(null);
      }

      return fetchStatus(res?.data);
    } catch (e) {
      setQrCodeStatus(-1);
      if (e instanceof Error) {
        enqueueSnackbar(e.message, {
          variant: 'error'
        });
      }
    }
  };

  useEffect(() => {
    if (device.isWeChat) {
      initWechat();
    }
  }, []);

  useEffect(() => {
    if (code) {
      QRCode.toDataURL(`https://book.xuezhu.wang/web/confirm?code=${code}`, {
        errorCorrectionLevel: 'H',
        width: 560,
        margin: 2,
        color: {
          dark: rgbToHex(theme.palette.text.primary),
          light: '#00000000' // 透明
        }
      }).then((url) => setQrCode(url));
    } else {
      setQrCode('');
    }
  }, [code, theme.palette.text.primary]);

  return (
    <LoginContext.Provider value={{ handleLogin, handleOpenApp }}>
      {props.children}

      <Dialog aria-labelledby="login-dialog-title" onClose={handleClose} open={open}>
        <DialogTitle id="login-dialog-title" sx={{ userSelect: 'none' }} align="center">
          使用微信扫码登录
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500]
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers sx={{ borderBottom: 0 }}>
          <Box
            sx={{
              width: 280,
              height: 280,
              userSelect: 'none',
              overflow: 'hidden',
              position: 'relative',
              img: {
                width: '100%',
                height: '100%',
                display: 'block'
              }
            }}
          >
            {!!qrCode && qrCodeStatus === 0 ? (
              <img src={qrCode} alt="微信登录二维码" draggable="false" />
            ) : (
              <>
                <QrCode2Icon
                  sx={{
                    width: '110%',
                    height: '110%',
                    display: 'block',
                    position: 'relative',
                    left: '-5%',
                    top: '-5%',
                    zIndex: 0,
                    opacity: 0.03
                  }}
                />
                {qrCodeStatus === 0 && qrCode === '' && (
                  <QrCodeMask>
                    <CircularProgress sx={{ svg: { m: 0, width: 'auto', height: 'auto' } }} />
                  </QrCodeMask>
                )}
                {qrCodeStatus === 1 && (
                  <QrCodeMask>
                    <CheckCircleRoundedIcon color="success" />
                    <Typography variant="body1">已扫码，等待确认登陆</Typography>
                  </QrCodeMask>
                )}
                {qrCodeStatus === 2 && (
                  <QrCodeMask>
                    <CheckCircleRoundedIcon color="success" />
                    <Typography variant="body1">登录成功</Typography>
                  </QrCodeMask>
                )}
                {qrCodeStatus === 3 && (
                  <QrCodeMask onClick={loadQrCode} sx={{ cursor: 'pointer' }}>
                    <BlockIcon color="error" />
                    <Typography variant="body1">已拒绝登录</Typography>
                    <Typography variant="body2" color="info" sx={{ mt: 1 }}>
                      点击刷新二维码
                    </Typography>
                  </QrCodeMask>
                )}
                {qrCodeStatus === 4 && (
                  <QrCodeMask onClick={loadQrCode} sx={{ cursor: 'pointer' }}>
                    <NoFlashRoundedIcon color="disabled" />
                    <Typography variant="body1">二维码已过期</Typography>
                    <Typography variant="body2" color="info" sx={{ mt: 1 }}>
                      点击刷新二维码
                    </Typography>
                  </QrCodeMask>
                )}
                {qrCodeStatus === -1 && (
                  <QrCodeMask onClick={loadQrCode} sx={{ cursor: 'pointer' }}>
                    <ErrorOutlineRoundedIcon color="error" />
                    <Typography variant="body1">二维码获取失败</Typography>
                    <Typography variant="body2" color="info" sx={{ mt: 1 }}>
                      点击重试
                    </Typography>
                  </QrCodeMask>
                )}
              </>
            )}
          </Box>
        </DialogContent>
      </Dialog>

      <MpCodeDialog onClose={() => setOpenMpCode(false)} open={openMpCode} />
    </LoginContext.Provider>
  );
};

export default observer(LoginProvider);
