import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function CunJinIcon(props: Omit<SvgIconProps, 'viewBox'>) {
  return (
    <SvgIcon {...props} viewBox="0 0 808 808">
      <path
        d="M723.8 84.4C647.5 8.5 537.9 0 404 0 269.9 0 160.1 8.6 83.9 84.7S0 270.4 0 404.4 7.7 648 84 724.1 269.9 808 404 808s243.8-7.7 320-83.9 84-185.8 84-319.8c0-134.1-7.8-243.8-84.2-319.9z"
        fill="#1890f8"
      />
      <path
        d="M548.5 232.3c6 2.2 12.5 7.8 28.7 24.7 29.5 30.8 41.5 40.3 79.6 63.1 10 6.1 20.2 12.5 22.5 14.4 13.8 11.2 15.4 24.4 4.4 36.1-4.2 4.4-7.6 6.2-12.3 6.1-6.5-.1-10.1-2.4-21.6-14.1-13.4-13.6-17.5-16.3-25.5-17.2-9.3-1-19.5 4.9-29.4 16.7-2.5 2.9-21.2 28.5-41.8 57-20.6 28.5-42.7 58.9-49.2 67.6-22.9 30.7-44.5 56.6-55.5 66.5-16.2 14.7-34.8 23.4-59.1 27.8-3.3.5-10.7.9-21.5.9-15.2-.1-17-.2-25.1-1.9-30.7-6.5-60-23.4-86.4-49.5-26-25.8-40.9-54.8-45-87.4-1.1-9.4-.4-27.5 1.4-36 5.9-27.2 23.4-54.3 44.7-68.7 14.3-9.7 29.6-14.3 47.3-14.3 20.8 0 38.5 7.6 54.5 23.4 8.6 8.5 14.2 17.6 17.2 28.1 2.2 7.8 2.2 19.7 0 26.8-5.3 17-17.5 28.2-33.4 30.7-18.3 2.8-34.4-12.7-33.3-32.1.6-10.8 7.1-18.5 16.8-20.1 1.6-.3 2.9-.7 2.9-1 0-.7-3.6-5.7-5.2-7-2.2-2-7.4-4-11.6-4.4-10.5-1.1-26.6 6.4-35.6 16.6-13.8 15.5-20.4 35.4-18.4 55.2 2.9 27.7 17.6 51.9 43.7 71.5 42.4 31.9 80.4 31.9 114.6.1 11.3-10.5 31.5-34.8 52.7-63.3 10.6-14.2 79.5-109.5 83.9-115.9 3.3-4.8 8.2-15.3 9.4-20.3 1.6-6.4 1-12.9-1.6-18.7-3.4-7.3-9.1-12.1-23.4-20.2-12.5-7-16.6-12.3-16.5-21.8.1-6.3 1.6-10.2 6.2-15.1 6-6.5 11.6-7.8 20.9-4.3z"
        fill="#f6f15f"
      />
      <path
        d="M323.5 223.6c17.4 2 31.7 5.7 46.3 11.9 32.8 13.9 58.7 39.2 73.2 71.3 13.3 29.6 16.8 63.9 9.9 96-2.7 13-10.8 31.7-15.9 37.3-1.6 1.6-4.6 4-6.9 5.2-3.6 1.9-4.7 2.2-7.8 1.9-4.6-.4-7.8-2.7-10.6-7.6-3.1-5.4-2.9-9.7 1.7-27.3 8.7-33.4 10.2-49.6 6.5-67.6-7-34.3-28.6-59.5-62.8-73.6-8.5-3.5-22.4-7.2-31.9-8.5-9.4-1.4-32.4-1.2-42.2.3-43.9 6.7-84.3 30-107.1 61.8-5.5 7.7-12.7 21.7-15.5 30.4-6.3 19.8-7.4 41-3 62.8 3.3 16.9 10.6 34.1 18.8 45 5.2 6.8 15.8 16.9 23.7 22.4 9.8 6.9 13.2 9.9 15.7 13.6 5.2 8.2 2.7 19.6-5.3 24.4-4.4 2.5-9 2.4-16.2-.6-18.4-7.6-41.3-30.5-54.6-54.6-8-14.4-14.6-36.6-17.6-58.8-1.4-10.6-1.6-31.7-.3-41.6 4.3-34.2 18-62.9 41.6-87.1 14-14.2 29.2-25.4 47.7-34.8 25.1-12.7 50.5-20.3 77-23 6.6-.4 27.7-.1 35.6.8z"
        fill="#ddeefe"
      />
    </SvgIcon>
  );
}

export default CunJinIcon;
