import merge from 'lodash/merge';
import { FC } from 'react';

import Box, { BoxProps } from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { SxProps, Theme } from '@mui/material/styles';

const BookCover: FC<
  Omit<BoxProps, 'sx'> & {
    sx?: Omit<Omit<SxProps<Theme>, 'width'>, 'height'>;
    src?: string;
    alt?: string;
    width?: number;
    height?: number;
    skeleton?: boolean;
  }
> = (props) => {
  const { src = '', alt = '', skeleton = false, sx } = props;

  if (skeleton) {
    return <Skeleton variant="rectangular" sx={sx} />;
  }

  return (
    <Box
      sx={merge(
        {
          width: 96,
          height: 139,
          bgcolor: '#eee',
          position: 'relative',
          userSelect: 'none',
          img: { width: '100%', height: '100%', display: 'block', objectFit: 'cover' },
          '&:after': {
            content: '""',
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundImage:
              'linear-gradient(90deg, hsla(0, 0%, 63.1%, .25), rgba(21, 21, 20, .1) 1%, hsla(0, 0%, 100%, .15) 4%, hsla(0, 0%, 58%, .1) 8%, hsla(0, 0%, 89%, 0) 57%, rgba(223, 218, 218, .03) 91%, rgba(223, 218, 218, .05) 98%, hsla(0, 0%, 100%, .1))',
            boxShadow: 'inset 0 0 0 0 rgba(0, 0, 0, .1)'
          }
        },
        sx
      )}
    >
      <img src={src} alt={`${alt}书籍封面`} loading="lazy" draggable="false" />
    </Box>
  );
};

export default BookCover;
