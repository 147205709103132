import { zhCN } from '@mui/material/locale';
import { darken, getOverlayAlpha, lighten, createTheme as muiCreateTheme } from '@mui/material/styles';

import components from './components';

export const createTheme = (mode?: 'light' | 'dark') => {
  return muiCreateTheme(
    {
      colorSchemes: {
        light:
          !mode || mode === 'light'
            ? {
                palette: {
                  background: {
                    card: '#fff',
                    banner: darken('#f4f5f7', getOverlayAlpha(0.65))
                  }
                }
              }
            : false,
        dark:
          !mode || mode === 'dark'
            ? {
                palette: {
                  background: {
                    card: lighten('#121212', getOverlayAlpha(1)),
                    banner: lighten('#121212', getOverlayAlpha(0.65))
                  }
                }
              }
            : false
      },
      typography: {
        fontFamily: ['-apple-system', 'SF UI Text', 'PingFang SC', 'Lucida Grande', 'STheiti', 'Microsoft YaHei', 'sans-serif'].join(','),
        fontFamilySerif: '"SourceHanSerifCN", "宋体", serif'
      },
      components
    },
    zhCN
  );
};

const theme = createTheme();

declare module '@mui/material/styles' {
  interface TypeBackground {
    card: string;
    banner: string;
  }

  interface TypographyVariantsOptions {
    fontFamilySerif?: string;
  }

  interface TypographyVariants {
    fontFamilySerif: string;
  }
}

export default theme;
