import { Link } from 'react-router-dom';

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

function Menu(props: { inline?: boolean; list: { slug: string; name: string }[]; slug: string }) {
  return (
    <List
      sx={{
        position: props.inline ? 'relative' : 'fixed',
        bottom: 0,
        top: 64,
        overflow: 'auto',
        scrollbarWidth: 'none',
        width: 160,
        py: 2.5,
        borderRight: (theme) => `0.5px solid ${theme.palette.divider}`
      }}
    >
      {!!props.list.length &&
        props.list.map((category) => (
          <ListItemButton component={Link} to={`/category/${category.slug}`} key={category.slug} selected={category.slug === props.slug}>
            <ListItemText primary={category.name} />
          </ListItemButton>
        ))}
    </List>
  );
}

export default Menu;
