import { FC } from 'react';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

const Footer: FC = () => {
  return (
    <Box sx={{ py: 8 }}>
      <Typography variant="body2" textAlign="center" color="textDisabled" gutterBottom>
        &copy;2023-{new Date().getFullYear()} 烟台云英网络科技有限公司版权所有
      </Typography>
      <Typography variant="body2" textAlign="center" color="textDisabled">
        <Link href="https://beian.miit.gov.cn" target="_blank" rel="nofollow noopener noreferrer" color="textDisabled" underline="hover">
          京ICP备12014819号-4
        </Link>
      </Typography>
    </Box>
  );
};

export default Footer;
