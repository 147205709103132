import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import { getBookChapterDetail, getBookDetail, updateBookLike } from 'api';
import useAuthActionCallback from 'hooks/useAuthActionCallback';

import FontContext from './ReaderContext';
import Header from './components/Header';
import MainContent from './components/MainContent';
import ToolBar from './components/ToolBar';

const HISTORY_FONT_SIZE_KEY = 'CJ_READER_FONT_SIZE';
const HISTORY_FONT_FAMILY_KEY = 'CJ_READER_FONT_FAMILY';

function Reader() {
  const theme = useTheme();
  const { id, book_id } = useParams();
  const [bookDetail, setBookDetail] = useState<BookDetail | null>(null);
  const [detail, setDetail] = useState<BookChapterDetail | null>(null);
  const [fontSize, setFontSize] = useState(18);
  const [fontFamily, setFontFamily] = useState(theme.typography.fontFamily || '');

  const getDetail = async (id: number | string) => {
    const res = await getBookChapterDetail({ id });
    setDetail(res.data || null);
  };

  const fetchBookDetail = async (id: number | string) => {
    const res = await getBookDetail({ id });
    setBookDetail(res.data || null);
  };

  const handleSetFontSize = useCallback((size: number) => {
    window.localStorage.setItem(HISTORY_FONT_SIZE_KEY, `${size}`);
    setFontSize(size);
  }, []);

  const handleSetFontFamily = useCallback((font: string) => {
    window.localStorage.setItem(HISTORY_FONT_FAMILY_KEY, font);
    setFontFamily(font);
  }, []);

  const handleStar = useAuthActionCallback(async (bookId: number, isStar: 0 | 1) => {
    await updateBookLike({
      book_ids: [bookId],
      is_like: isStar
    });

    fetchBookDetail(bookId);
  }, []);

  useEffect(() => {
    if (id) {
      getDetail(id);
    }
    if (book_id) {
      fetchBookDetail(book_id);
    }
  }, [book_id, id]);

  useEffect(() => {
    const size = window.localStorage.getItem(HISTORY_FONT_SIZE_KEY);
    const font = window.localStorage.getItem(HISTORY_FONT_FAMILY_KEY);

    if (size && !isNaN(Number(size))) {
      setFontSize(Number(size));
    }

    if (font) {
      setFontFamily(font);
    }

    const storageEvent = (e: StorageEvent) => {
      if (e.key === HISTORY_FONT_SIZE_KEY && !isNaN(Number(e.newValue))) {
        setFontSize(Number(e.newValue));
      }
      if (e.key === HISTORY_FONT_FAMILY_KEY && e.newValue) {
        setFontFamily(e.newValue);
      }
    };

    window.addEventListener('storage', storageEvent);

    return () => window.removeEventListener('storage', storageEvent);
  }, []);

  return (
    <FontContext.Provider
      value={{
        book: bookDetail,
        chapter: detail,
        fontSize,
        setFontSize: handleSetFontSize,
        fontFamily,
        setFontFamily: handleSetFontFamily,
        setStar: handleStar
      }}
    >
      <Box
        sx={(theme) => ({
          width: { xs: '100%', md: '80%' },
          mx: 'auto',
          minHeight: '100vh',
          bgcolor: { md: theme.palette.background.card }
        })}
      >
        <Header />
        <MainContent />
        {!!detail && <ToolBar />}
      </Box>
    </FontContext.Provider>
  );
}

export default Reader;
