import { Link } from 'react-router-dom';

import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import RadarRoundedIcon from '@mui/icons-material/RadarRounded';
import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import BookCover from 'components/BookCover';

function BookItem(props: { index?: number; book?: BookListItem; skeleton?: boolean }) {
  const { index, book, skeleton } = props;
  return (
    <ListItemButton component="li" sx={{ p: 0 }}>
      <Stack component={Link} to={`/book/${book?.id}`} direction="row" alignItems="center" sx={{ py: 2.5, textDecoration: 'none', flex: 1 }}>
        <Typography
          component="div"
          variant="h6"
          align="center"
          color="textPrimary"
          fontFamily="DIN"
          fontWeight={500}
          sx={{
            width: 76,
            flexShrink: 0,
            display: {
              xs: 'none',
              md: 'block'
            }
          }}
        >
          {skeleton ? <Skeleton variant="text" sx={{ width: '0.5em', mx: 'auto' }} /> : index}
        </Typography>
        <BookCover
          sx={{
            width: {
              xs: 86,
              md: 108
            },
            height: {
              xs: 124,
              md: 156
            }
          }}
          src={book?.cover}
          alt={book?.title}
          skeleton={skeleton}
        />
        <Box sx={{ flex: 1, pl: { xs: 2, md: 4 } }}>
          <Typography
            variant="h6"
            component="h4"
            fontFamily="SourceHanSerifCN"
            color="textPrimary"
            fontWeight={700}
            sx={(theme) => ({
              xs: theme.typography.h6,
              md: theme.typography.h6
            })}
          >
            {skeleton ? <Skeleton variant="text" sx={{ width: '40%' }} /> : book?.title}
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
            {skeleton ? <Skeleton variant="text" sx={{ width: '10%' }} /> : book?.author}
          </Typography>
          <Stack direction="row" spacing={2} sx={{ mt: 1 }} flexWrap="wrap">
            {(!!skeleton || !!book?.total_duration) && (
              <Stack
                component={Typography}
                direction="row"
                variant="body2"
                spacing={0.5}
                alignItems="center"
                sx={(theme) => ({ color: theme.palette.text.secondary })}
              >
                {!!skeleton ? (
                  <Skeleton width={75} variant="text" />
                ) : (
                  <>
                    <AccessTimeRoundedIcon fontSize="inherit" titleAccess="时长" color="inherit" />
                    <span>{Math.floor((book?.total_duration || 0) / 60)}分钟</span>
                  </>
                )}
              </Stack>
            )}
            {(!!skeleton || !!book?.pointview_count) && (
              <Stack
                component={Typography}
                variant="body2"
                direction="row"
                spacing={0.5}
                alignItems="center"
                sx={(theme) => ({ color: theme.palette.text.secondary })}
              >
                {!!skeleton ? (
                  <Skeleton width={82} variant="text" />
                ) : (
                  <>
                    <RadarRoundedIcon fontSize="inherit" titleAccess="观点" />
                    <span>{Math.floor(book?.pointview_count || 0)}个观点</span>
                  </>
                )}
              </Stack>
            )}
          </Stack>
          <Typography
            variant="body1"
            color="textDisabled"
            sx={{
              mt: 1,
              maxHeight: '4.5rem',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              WebkitLineClamp: 2,
              MozLineClamp: 2,
              lineClamp: 2,
              display: { xs: 'none', md: '-webkit-box' },
              WebkitBoxOrient: 'vertical',
              boxOrient: 'vertical'
            }}
          >
            {skeleton ? (
              <>
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" sx={{ width: '30%' }} />
              </>
            ) : (
              book?.desc
            )}
          </Typography>
        </Box>
      </Stack>
    </ListItemButton>
  );
}

export default BookItem;
