import { action, computed, makeObservable, observable } from 'mobx';

import { getUserInfo } from 'api';

export default class User {
  userInfo: UserInfo | null = null;
  isReady = false;

  constructor() {
    makeObservable(this, {
      userInfo: observable.deep,
      isReady: observable,
      isLogin: computed.struct,
      init: action.bound,
      fetchUserInfo: action.bound
    });
    this.init();
  }

  get isLogin() {
    return !!this.userInfo;
  }

  async init() {
    await this.fetchUserInfo();
    this.isReady = true;
  }

  async fetchUserInfo() {
    const res = await getUserInfo();

    if (res.statusCode === 0) {
      this.userInfo = res.data;
    } else {
      this.userInfo = null;
    }

    return res;
  }
}
