import { FC } from 'react';
import { Link } from 'react-router-dom';

import Grid from '@mui/material/Grid2';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';

const CategoryItem: FC<{
  category?: {
    book_count: number;
    cover: string;
    id: number;
    name: string;
    slug: string;
  };
}> = (props) => {
  const category = props.category;

  return (
    <Grid
      component="li"
      size={{ xs: 12, sm: 6, md: 3 }}
      sx={(theme) => ({
        bgcolor: theme.palette.background.card,
        borderRadius: 3
      })}
    >
      <Stack
        component={category ? Link : 'div'}
        to={category ? `/category/${category.slug}` : ''}
        direction="row"
        spacing={2}
        alignItems="center"
        sx={{ p: 2.5, textDecoration: 'none' }}
      >
        {category ? (
          <Box
            sx={{
              width: 48,
              height: 69,
              bgcolor: '#eee',
              img: { width: '100%', height: '100%', display: 'block', objectFit: 'cover', userSelect: 'none' }
            }}
          >
            <img src={category.cover} alt={category.name} loading="lazy" draggable="false" />
          </Box>
        ) : (
          <Skeleton variant="rectangular" width={48} height={69} />
        )}
        <Box sx={{ flex: 1 }}>
          <Typography noWrap color="textPrimary" variant="subtitle1">
            {category ? category.name : <Skeleton variant="text" sx={{ width: 64 }} />}
          </Typography>
          <Typography noWrap color="textDisabled" variant="subtitle2" sx={{ mt: 0.5 }}>
            {category ? `${category.book_count} 本书籍` : <Skeleton variant="text" sx={{ width: 64 }} />}
          </Typography>
        </Box>
      </Stack>
    </Grid>
  );
};

export default CategoryItem;
