import ReaderContext from 'containers/Reader/ReaderContext';
import { FC, Fragment, useContext, useMemo, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link } from 'react-router-dom';

import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import BookCover from 'components/BookCover';

import ReaderDrawer from '../ReaderDrawer';
import ToolBarButton from '../ToolBarButton';

const ToolBarButtonMenu: FC = () => {
  const { chapter } = useContext(ReaderContext);
  const [open, setOpen] = useState(false);
  const menuList = useMemo(() => {
    const list: { title: string; id: string; level: number }[] = [];
    chapter?.sections.forEach((section) => {
      section.nodes.forEach((node) => {
        if (node.title) {
          list.push({
            title: node.title,
            id: `node-${node.id}`,
            level: 0
          });
        }
        if (Array.isArray(node.ext.data)) {
          (node.ext.data as { title: string }[]).forEach((item, idx) => {
            list.push({
              title: item.title,
              id: `node-${node.id}-${idx}`,
              level: 1
            });
          });
        }
      });
    });
    return list;
  }, [chapter?.sections]);

  const handleScrollToNode = (id: string) => {
    const wrap = document.getElementById(id);
    if (wrap) {
      const rect = wrap.getBoundingClientRect();
      const offset = document.getElementById('reader-header')?.getBoundingClientRect().height || 0;
      window.scrollTo({ left: 0, top: window.scrollY + rect.top - offset - 32, behavior: 'smooth' });
      setOpen(false);
    }
  };

  return (
    <>
      <ToolBarButton title="章节列表" onClick={() => setOpen(true)}>
        <MenuIcon />
      </ToolBarButton>
      {!!chapter && (
        <ReaderDrawer open={open} onClose={() => setOpen(false)}>
          <Box sx={{ py: 4.5, px: { xs: 2.5, sm: 4.5 } }}>
            <Stack
              component={Link}
              to={`/book/${chapter.book_id}`}
              direction="row"
              sx={{ alignItems: 'center', textDecoration: 'none' }}
              spacing={2}
            >
              <BookCover src={chapter.book_cover} sx={{ width: 64, height: 92 }} />
              <Box sx={{ flex: 1 }}>
                <Typography fontFamily="SourceHanSerifCN" variant="h6" component="p" fontWeight={700} gutterBottom color="textPrimary">
                  {chapter.book_title}
                </Typography>
                <Typography fontFamily="SourceHanSerifCN" variant="body2" fontWeight={500} whiteSpace="nowrap" color="textSecondary">
                  {chapter.book_author}
                </Typography>
              </Box>
            </Stack>
          </Box>
          <Divider sx={{ mx: { xs: 2.5, sm: 4.5 }, my: 0, opacity: 0.5 }} />
          <PerfectScrollbar
            style={{
              flex: 1,
              listStyle: 'none',
              margin: 0,
              padding: 0,
              position: 'relative',
              paddingTop: '8px',
              paddingBottom: '8px'
            }}
          >
            {menuList.map((item) => {
              return (
                <Fragment key={item.id}>
                  <ListItem disablePadding>
                    <ListItemButton
                      sx={{ px: { xs: item.level ? 4.5 : 2.5, sm: item.level ? 6.5 : 4.5 } }}
                      onClick={() => handleScrollToNode(item.id)}
                    >
                      <ListItemText primary={item.title} />
                    </ListItemButton>
                  </ListItem>
                  <Divider component="li" sx={{ mx: 4.5, opacity: 0.5 }} />
                </Fragment>
              );
            })}
          </PerfectScrollbar>

          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            sx={(theme) => ({
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
              display: {
                md: 'none'
              }
            })}
          >
            <CloseIcon />
          </IconButton>
        </ReaderDrawer>
      )}
    </>
  );
};

export default ToolBarButtonMenu;
