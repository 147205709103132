import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { Link } from 'react-router-dom';

import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import { useColorScheme, useTheme } from '@mui/material/styles';

import Logo from 'components/icons/Logo';
import useLogin from 'hooks/useLogin';
import useStore from 'hooks/useStore';
import { isAndroid, isIOS } from 'utils/device';

const Header: FC = () => {
  const store = useStore();
  const { handleLogin, handleOpenApp } = useLogin();
  const theme = useTheme();
  const { setMode } = useColorScheme();

  return (
    <>
      <AppBar position="fixed" color="default">
        <Container>
          <Toolbar disableGutters>
            <Link to="/">
              <Logo
                sx={{
                  width: { xs: 120, md: 142 },
                  height: { xs: 28, md: 32 },
                  display: 'block'
                }}
                colorType={theme.palette.mode}
              />
            </Link>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              divider={<Divider flexItem variant="middle" orientation="vertical" sx={{ height: '18px', alignSelf: 'center' }} />}
              sx={{ flexGrow: 1, justifyContent: 'flex-end' }}
            >
              <Button size="small" variant="text" color="inherit" sx={{ minWidth: 'auto' }} onClick={handleOpenApp}>
                手机版
              </Button>
              <Button
                size="small"
                variant="text"
                color="inherit"
                sx={{ p: '6px', minWidth: 'auto', borderRadius: 3, fontSize: 18 }}
                onClick={() => setMode(theme.palette.mode === 'dark' ? 'light' : 'dark')}
              >
                {theme.palette.mode === 'dark' ? (
                  <LightModeIcon fontSize="inherit" sx={{ display: 'block' }} />
                ) : (
                  <DarkModeIcon fontSize="inherit" sx={{ display: 'block' }} />
                )}
              </Button>
              {!isIOS && !isAndroid && !store.user.isLogin && (
                <Button size="small" variant="text" color="inherit" sx={{ minWidth: 'auto' }} onClick={handleLogin}>
                  登录
                </Button>
              )}
              {!!store.user.isLogin && !!store.user.userInfo && (
                <Avatar sx={{ width: 30, height: 30, borderRadius: 3 }} src={store.user.userInfo.avatar}>
                  {store.user.userInfo.name}
                </Avatar>
              )}
            </Stack>
          </Toolbar>
        </Container>
      </AppBar>
      <Toolbar />
    </>
  );
};

export default observer(Header);
