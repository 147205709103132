import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { getBookCelebrityRecommend, getBookChapterList, getBookDetail, updateBookLike } from 'api';
import TitleBar from 'components/TitleBar';
import useAuthActionCallback from 'hooks/useAuthActionCallback';

import BookInfo from './components/BookInfo';
import TextBox from './components/TextBox';

function Book() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [detail, setDetail] = useState<BookDetail | null>(null);
  const [chapter, setChapter] = useState<ChapterListItem | null>(null);
  const [recList, setRecList] = useState<Awaited<ReturnType<typeof getBookCelebrityRecommend>>['data']>([]);

  const getDetail = async (id: number | string) => {
    setLoading(true);
    const bookRes = await getBookDetail({ id });
    setDetail(bookRes.data || null);
    setLoading(false);
  };

  const getOther = async (id: number | string) => {
    const [recRes, chapterRes] = await Promise.all([getBookCelebrityRecommend({ id }), getBookChapterList({ id })]);
    setRecList(recRes.data || []);
    setChapter(chapterRes?.data?.summary || null);
  };

  const handleStar = useAuthActionCallback(async (bookId: number, isStar: number) => {
    await updateBookLike({
      book_ids: [bookId],
      is_like: isStar
    });
    getDetail(bookId);
  }, []);

  useEffect(() => {
    if (id) {
      getDetail(id);
      getOther(id);
    }
  }, [id]);

  return (
    <>
      <BookInfo detail={detail} chapter={chapter} loading={loading} onMark={handleStar} />
      <Container>
        {!!detail?.desc && <TextBox title="内容简介" text={detail.desc} />}
        {!!detail?.author_intro && <TextBox title="作者简介" text={detail.author_intro} />}
        {!!recList.length && (
          <Box sx={{ mt: 4 }}>
            <TitleBar title="名人推荐" />
            {recList.map((rec, idx) => {
              return (
                <Box key={rec.id} sx={{ mb: idx < recList.length - 1 ? 2 : 0 }}>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Avatar src={rec.avatar} alt={rec.name} sx={{ width: 56, height: 56 }} />
                    <Box>
                      <Typography variant="h6" fontFamily="SourceHanSerifCN" fontWeight={700}>
                        {rec.name}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {rec.title}
                      </Typography>
                    </Box>
                  </Stack>
                  <Paper
                    sx={(theme) => ({
                      boxShadow: 'none',
                      borderRadius: 3,
                      py: 2,
                      px: 3,
                      mt: 1,
                      position: 'relative',
                      '&:before': {
                        content: '""',
                        width: '0',
                        height: '0',
                        borderStyle: 'solid',
                        borderWidth: '5px',
                        borderColor: `${theme.palette.background.card} transparent transparent ${theme.palette.background.card}`,
                        position: 'absolute',
                        left: '28px',
                        bottom: '100%',
                        transform: 'translate(-50%,50%) rotate(45deg)'
                      }
                    })}
                  >
                    <Typography variant="body1" whiteSpace="pre-wrap">
                      {rec.endors}
                    </Typography>
                  </Paper>
                </Box>
              );
            })}
          </Box>
        )}
      </Container>
    </>
  );
}

export default Book;
