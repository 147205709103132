import { MP_APPID } from 'config';

import { getMpSchema } from 'api';

import { isAndroid, isIOS } from './device';
import { openSchema } from './openSchema';

export default async function openMp(path: string, query: string, fallbackCallback?: Function) {
  if (!isIOS && !isAndroid) {
    typeof fallbackCallback === 'function' && fallbackCallback();
    return;
  }

  const res = await getMpSchema({
    appid: MP_APPID,
    path,
    query,
    env_version: 'release'
  });

  if (res.statusCode === 0) {
    openSchema(res.data, fallbackCallback);
    return;
  }

  typeof fallbackCallback === 'function' && fallbackCallback();
}
