import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import Container from '@mui/system/Container';

import Logo from 'components/icons/Logo';

function Banner() {
  const theme = useTheme();

  return (
    <Box sx={(theme) => ({ py: 6.5, bgcolor: theme.palette.background.banner })}>
      <Container>
        <Box component="h1" sx={{ m: 0 }}>
          <Logo sx={{ width: 160, height: 36, display: 'block', mx: 'auto' }} colorType={theme.palette.mode} />
        </Box>
        <Typography textAlign="center" component="h2" variant="h5" color="textSecondary" sx={{ mt: 2 }}>
          进一寸有一寸的欢喜
        </Typography>
      </Container>
    </Box>
  );
}

export default Banner;
