import { FC, PropsWithChildren } from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const TitleBar: FC<PropsWithChildren<{ title: string }>> = (props) => {
  return (
    <Stack sx={{ px: { xs: 0.5, sm: 1 }, py: { xs: 1.5, sm: 2.5 } }} direction="row" spacing={2} alignItems="center" justifyContent="space-between">
      <Typography variant="h6" component="h2" fontFamily="SourceHanSerifCN" fontWeight={700}>
        {props.title}
      </Typography>
      {props.children}
    </Stack>
  );
};

export default TitleBar;
