import { Outlet } from 'react-router-dom';

import Box from '@mui/material/Box';

import Footer from 'components/Footer';
import Header from 'components/Header';

function Home() {
  return (
    <Box>
      <Header />
      <main>
        <Outlet />
      </main>
      <Footer />
    </Box>
  );
}

export default Home;
