import { FC, useContext } from 'react';

import Box from '@mui/material/Box';
import { darken, lighten, styled } from '@mui/material/styles';

import ReaderContext from '../../ReaderContext';
import NodeWrap from '../NodeWrap';
import Section from '../Section';

const ContentWrap = styled(Box)(({ theme }) => ({
  color: lighten(theme.palette.text.primary, 0.05),
  overflow: 'hidden',
  pb: 8,
  letterSpacing: '0.05em',
  lineHeight: 1.8,

  '& p': {
    my: '1.5em'
  },
  '& ol': {
    paddingInlineStart: '2em'
  },
  '& ul': {
    paddingInlineStart: '1.5em'
  },
  '& li': {
    my: '0.25em'
  },
  '& h1': {
    fontSize: '1.5em'
  },
  '& h2': {
    fontSize: '1.25em'
  },
  '& h3': {
    fontSize: '1.175em'
  },
  'h1,h2': { textAlign: 'center' },
  'h1,h2,h3,h4': {
    color: theme.palette.text.primary,
    lineHeight: 1.375,
    letterSpacing: '0.2em',
    marginBottom: '.5em',
    marginTop: '1.5em'
  },
  '& table': {
    width: '100%',
    border: `0.5px solid ${theme.palette.divider}`,
    borderCollapse: 'collapse',
    fontSize: '0.83em',
    margin: '0.5em auto 1.5em',

    'th,td': {
      border: `0.5px solid ${theme.palette.divider}`,
      padding: '0.2em 0.5em'
    }
  },
  ...theme.applyStyles('dark', {
    color: darken(theme.palette.text.primary, 0.15)
  })
}));

const MainContent: FC = () => {
  const { fontSize, fontFamily, chapter } = useContext(ReaderContext);

  return (
    <ContentWrap
      sx={{
        fontSize,
        fontFamily,
        pb: {
          xs: 8,
          md: 3
        }
      }}
    >
      {chapter ? (
        chapter.sections?.map((section) => <Section key={section.id} section={section} />)
      ) : (
        <>
          <NodeWrap skeleton />
          <NodeWrap skeleton />
        </>
      )}
    </ContentWrap>
  );
};

export default MainContent;
