import { Link } from 'react-router-dom';

import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import RadarRoundedIcon from '@mui/icons-material/RadarRounded';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import BookCover from 'components/BookCover';

function BookInfo(props: {
  detail?: BookDetail | null;
  chapter?: ChapterListItem | null;
  onMark?: (bookId: number, isStar: number) => Promise<void>;
  loading: boolean;
}) {
  const { detail, chapter, loading, onMark } = props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const skeleton = !detail && loading;

  return (
    <Box sx={(theme) => ({ py: { xs: 3, sm: 6.5 }, bgcolor: theme.palette.background.banner })}>
      <Container>
        <Stack direction="row" spacing={matches ? 4 : 2}>
          <BookCover
            sx={{ width: { xs: 112, sm: 174 }, height: { xs: 160, sm: 250 } }}
            src={detail?.cover}
            alt={detail?.title}
            skeleton={skeleton}
          />
          <Stack spacing={matches ? 4 : 2} sx={{ flex: 1 }}>
            <Stack justifyContent="center" sx={{ flex: 1 }}>
              <Typography variant={matches ? 'h4' : 'h6'} component="h2" fontFamily="SourceHanSerifCN" fontWeight={700}>
                {skeleton ? <Skeleton variant="text" width="50%" /> : detail?.title || ''}
              </Typography>
              <Typography variant={matches ? 'body1' : 'body2'} sx={{ mt: { xs: 1, sm: 2 } }} color="textSecondary">
                {skeleton ? <Skeleton variant="text" width="30%" /> : detail?.author || ''}
              </Typography>
              <Stack direction="row" spacing={matches ? 4 : 2} sx={{ mt: { xs: 1, sm: 2 } }} flexWrap="wrap">
                {(!!skeleton || !!detail?.total_duration) && (
                  <Stack
                    component={Typography}
                    variant={matches ? 'body1' : 'body2'}
                    direction="row"
                    spacing={0.5}
                    alignItems="center"
                    sx={(theme) => ({ color: theme.palette.text.secondary })}
                  >
                    {!!skeleton ? (
                      <Skeleton width={75} variant="text" />
                    ) : (
                      <>
                        <AccessTimeRoundedIcon fontSize="inherit" titleAccess="时长" color="inherit" />
                        <span>{Math.floor((detail?.total_duration || 0) / 60)}分钟</span>
                      </>
                    )}
                  </Stack>
                )}
                {(!!skeleton || !!detail?.pointview_count) && (
                  <Stack
                    component={Typography}
                    variant={matches ? 'body1' : 'body2'}
                    direction="row"
                    spacing={0.5}
                    alignItems="center"
                    sx={(theme) => ({ color: theme.palette.text.secondary })}
                  >
                    {!!skeleton ? (
                      <Skeleton width={82} variant="text" />
                    ) : (
                      <>
                        <RadarRoundedIcon fontSize="inherit" titleAccess="观点" />
                        <span>{Math.floor(detail?.pointview_count || 0)}个观点</span>
                      </>
                    )}
                  </Stack>
                )}
              </Stack>
            </Stack>

            <Stack direction="row" spacing={matches ? 4 : 2} alignItems="center">
              {!!chapter && !!detail && !!chapter.id && (
                <Button
                  component={Link}
                  to={`/book/${detail.id}/reader/${chapter.id}`}
                  size={matches ? 'large' : 'small'}
                  variant="contained"
                  disableElevation
                >
                  开始阅读
                </Button>
              )}
              <IconButton
                aria-label="mark"
                size={matches ? 'large' : 'small'}
                onClick={() => !!detail && onMark && onMark(detail.id, detail.user_status?.is_star ? 0 : 1)}
              >
                {detail?.user_status?.is_star ? <BookmarkIcon color="error" /> : <BookmarkBorderIcon />}
              </IconButton>
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
}

export default BookInfo;
