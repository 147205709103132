import { Components, Theme, alpha } from '@mui/material/styles';

const MuiAppBar: Components<Theme>['MuiAppBar'] = {
  styleOverrides: {
    root: {
      variants: [
        {
          props: { color: 'default' },
          style: (props: { theme: Theme }) => ({
            background: alpha(props.theme.palette.background.default, 0.8),
            boxShadow: 'none',
            backdropFilter: `blur(8px)`,
            borderBottom: `0.5px solid ${props.theme.palette.divider}`
          })
        }
      ]
    }
  }
};

export default MuiAppBar;
