import { DependencyList, useCallback, useContext } from 'react';

import LoginContext from 'components/LoginProvider/LoginContext';

import useStore from './useStore';

export default function useAuthActionCallback<T extends Function>(callback: T, deps: DependencyList): T {
  const { handleLogin } = useContext(LoginContext);
  const { user } = useStore();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback<T>(
    ((...args: any) => {
      if (user.isLogin) {
        return callback(...args);
      } else {
        handleLogin();
      }
    }) as unknown as T,
    [handleLogin, user.isLogin, ...deps]
  );
}
