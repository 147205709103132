import { useEffect, useState } from 'react';

import Container from '@mui/system/Container';

import { getHomeBooks } from 'api';

import Banner from './components/Banner';
import Category from './components/Category';
import Recommend from './components/Recommend';

function Home() {
  const [list, setList] = useState<Awaited<ReturnType<typeof getHomeBooks>>['data']>([]);

  const getList = async () => {
    const res = await getHomeBooks();
    setList(res.data);
  };

  useEffect(() => {
    getList();
  }, []);

  console.log(list);

  return (
    <>
      <Banner />
      <Container>
        <Recommend />
        <Category />
      </Container>
    </>
  );
}

export default Home;
