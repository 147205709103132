import MuiAppBar from './MuiAppBar';
import MuiButton from './MuiButton';
import MuiCssBaseline from './MuiCssBaseline';

const components = {
  MuiAppBar,
  MuiButton,
  MuiCssBaseline
};

export default components;
