import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';

import { getBookList } from 'api';

import BookItem from '../BookItem';

function Books(props: { slug?: string }) {
  const [list, setList] = useState<Awaited<ReturnType<typeof getBookList>>['data']['list']>([]);
  const [conf, setConf] = useState({ page: 1, limit: 20, loading: false, hasMore: true });

  const loadList = useCallback(
    async (slug?: string, page: number = 1) => {
      if (conf.loading) return;
      setConf((config) => ({ ...config, loading: true }));

      if (page === 1) {
        setList([]);
      }

      const res = await getBookList({
        page: page,
        limit: conf.limit,
        category_slug: slug
      });

      if (res.statusCode === 0) {
        const { list: resList, pages } = res.data;

        setList((defaultList) => (page === 1 ? [...resList] : [...defaultList, ...resList]));

        setConf((config) => {
          return {
            ...config,
            page: pages.page,
            loading: false,
            hasMore: pages.page * pages.limit < pages.total
          };
        });
      } else {
        setConf((config) => ({ ...config, loading: false }));
      }
    },
    [conf]
  );

  const loadMore = useMemo(
    () =>
      debounce(() => {
        if (!conf.hasMore) return;
        loadList(props.slug, conf.page + 1);
      }, 300),
    [conf.hasMore, conf.page, loadList, props.slug]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    loadList(props.slug);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.slug]);

  useEffect(() => {
    if (!conf.hasMore || conf.loading) return;

    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
      if (scrollTop + clientHeight >= scrollHeight - 250) {
        loadMore();
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [conf.hasMore, conf.loading, loadMore]);

  return (
    <List sx={{ py: 2.5 }}>
      {!!list.length && list.map((item, idx) => <BookItem book={item} key={item.id} index={idx + 1} />)}
      {conf.loading && !list.length && (
        <>
          <BookItem skeleton />
          <BookItem skeleton />
          <BookItem skeleton />
          <BookItem skeleton />
        </>
      )}
      {!!list.length && conf.hasMore && (
        <Typography component="li" align="center" color="textDisabled" sx={{ my: 2 }}>
          <CircularProgress color="inherit" size={30} sx={{ display: 'block', mx: 'auto' }} />
        </Typography>
      )}
    </List>
  );
}

export default Books;
