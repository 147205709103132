import { useCallback, useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import Box from '@mui/system/Box';

import { getHomeRecommend } from 'api';
import BookCardItem from 'components/BookCardItem';
import BookCardList from 'components/BookCardList';
import TitleBar from 'components/TitleBar';

function Recommend() {
  const [list, setList] = useState<Awaited<ReturnType<typeof getHomeRecommend>>['data']>([]);
  const [loading, setLoading] = useState(true);
  const getList = async () => {
    setLoading(true);
    try {
      const res = await getHomeRecommend();
      setList(res.data);
    } catch (e) {}
    setLoading(false);
  };

  const handleChange = useCallback(() => {
    if (loading) return;
    getList();
  }, [loading]);

  useEffect(() => {
    getList();
  }, []);

  return (
    <Box sx={{ mt: 4 }}>
      <TitleBar title="热门精选">
        <Button size="small" disabled={loading} onClick={handleChange}>
          换一批
        </Button>
      </TitleBar>
      {!loading && list && (
        <BookCardList>
          {list.map((book) => (
            <BookCardItem key={book.id} book={book} />
          ))}
        </BookCardList>
      )}
      {loading && (
        <BookCardList>
          {Array(6)
            .fill('1')
            .map((n, idx) => (
              <BookCardItem key={idx} skeleton />
            ))}
        </BookCardList>
      )}
    </Box>
  );
}

export default Recommend;
