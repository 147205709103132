import Book from 'containers/Book';
import Cashier from 'containers/Cashier';
import Category from 'containers/Category';
import Home from 'containers/Home';
import Reader from 'containers/Reader';
import Vip from 'containers/Vip';
import Confirm from 'containers/web/Confirm';
import { createBrowserRouter } from 'react-router-dom';

import Wrapper from 'components/Wrapper';

const router = createBrowserRouter([
  { path: '/web/confirm', element: <Confirm /> },
  {
    path: '/book/:book_id/reader/:id',
    element: <Reader />
  },
  {
    path: '',
    element: <Wrapper />,
    children: [
      { path: '/', element: <Home /> },
      {
        path: '/cashier',
        element: <Cashier />
      },
      {
        path: '/vip',
        element: <Vip />
      },
      {
        path: '/category',
        element: <Category />
      },
      {
        path: '/category/:slug',
        element: <Category />
      },
      {
        path: '/book/:id',
        element: <Book />
      }
    ]
  }
]);

export default router;
