export default function dateFormat(time: number | string | Date, fmt: string = 'yyyy-MM-dd') {
  const date = new Date(time);
  const o = {
    'M+': date.getMonth() + 1, //月份
    'd+': date.getDate(), //日
    'H+': date.getHours(), //小时
    'm+': date.getMinutes(), //分
    's+': date.getSeconds(), //秒
    'q+': Math.floor((date.getMonth() + 3) / 3), //季度
    S: date.getMilliseconds() //毫秒
  };

  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(/(y+)/, (str) => (date.getFullYear() + '').substring(4 - str.length));
  }

  for (let k in o) {
    const reg = new RegExp('(' + k + ')');
    if (reg.test(fmt)) {
      fmt = fmt.replace(reg, (str) => {
        const value = `${o[k as keyof typeof o]}`;
        return str.length === 1 ? value : ('00' + value).substring(value.length);
      });
    }
  }
  return fmt;
}
