import { Link } from 'react-router-dom';

import Grid from '@mui/material/Grid2';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';

import BookCover from 'components/BookCover';

function BookCardItem(props: {
  book?: {
    id: number;
    title: string;
    cover: string;
    author: string;
  };
  skeleton?: boolean;
}) {
  const { book, skeleton } = props;
  return (
    <Grid
      component="li"
      size={{ xs: 6, sm: 4, lg: 2 }}
      sx={(theme) => ({
        bgcolor: theme.palette.background.card,
        borderRadius: 3
      })}
    >
      {!skeleton && !!book && (
        <Box
          component={Link}
          to={`/book/${book.id}`}
          sx={{ display: 'block', pt: 3, pb: 2, px: 2, textDecoration: 'none', height: '100%' }}
          draggable="false"
        >
          <BookCover sx={{ margin: '0 auto', width: 96, height: 139 }} src={book.cover} alt={book.title} />
          <Typography align="center" noWrap color="textPrimary" variant="subtitle1" sx={{ mt: 2 }}>
            {book.title}
          </Typography>
          <Typography align="center" noWrap color="textDisabled" variant="subtitle2" sx={{ mt: 1 }}>
            {book.author || <>&nbsp;</>}
          </Typography>
        </Box>
      )}
      {!!skeleton && (
        <Box sx={{ display: 'block', pt: 3, pb: 2, px: 2, textDecoration: 'none', height: '100%' }} draggable="false">
          <BookCover sx={{ margin: '0 auto', width: 96, height: 139 }} skeleton />
          <Typography align="center" noWrap color="textPrimary" variant="subtitle1" sx={{ mt: 2 }}>
            <Skeleton variant="text" sx={{ width: '70%', margin: '0 auto' }} />
          </Typography>
          <Typography align="center" noWrap color="textDisabled" variant="subtitle2" sx={{ mt: 1 }}>
            <Skeleton variant="text" sx={{ width: '50%', margin: '0 auto' }} />
          </Typography>
        </Box>
      )}
    </Grid>
  );
}

export default BookCardItem;
