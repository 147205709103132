import ReaderContext from 'containers/Reader/ReaderContext';
import { FC, useContext } from 'react';

import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';

import ToolBarButton from '../ToolBarButton';

const ToolBarButtonMark: FC = () => {
  const { book, setStar } = useContext(ReaderContext);

  return (
    <ToolBarButton
      title={book?.user_status?.is_star ? '取消收藏' : '加入收藏'}
      onClick={() => !!book && setStar(book.id, book.user_status?.is_star ? 0 : 1)}
    >
      {book?.user_status?.is_star ? <BookmarkIcon color="error" /> : <BookmarkBorderIcon />}
    </ToolBarButton>
  );
};

export default ToolBarButtonMark;
