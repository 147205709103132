import { FC } from 'react';

import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import { useColorScheme, useTheme } from '@mui/material/styles';

import ToolBarButton from '../ToolBarButton';

const ToolBarButtonTheme: FC = (props) => {
  const theme = useTheme();
  const { setMode } = useColorScheme();

  return (
    <ToolBarButton title="切换主题" onClick={() => setMode(theme.palette.mode === 'dark' ? 'light' : 'dark')}>
      {theme.palette.mode === 'dark' ? <LightModeIcon /> : <DarkModeIcon />}
    </ToolBarButton>
  );
};

export default ToolBarButtonTheme;
