import { observer } from 'mobx-react-lite';
import { FC, PropsWithChildren, useEffect, useState } from 'react';
import 'swiper/css';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';

import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import PersonIcon from '@mui/icons-material/Person';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ThemeProvider, styled } from '@mui/material/styles';

import { getVipList } from 'api';
import WeChatPayIcon from 'components/icons/WeChatPayIcon';
import useLogin from 'hooks/useLogin';
import useStore from 'hooks/useStore';
import { createTheme } from 'theme';
import dateFormat from 'utils/dateformat';
import device from 'utils/device';
import { initWechat } from 'utils/wechat';

import VipContext from './VipContext';

const SwiperBtn = styled(Box)<{ ownerState: { prev?: boolean; next?: boolean; disabled: boolean } }>(
  ({ theme, ownerState: { prev, next, disabled } }) => ({
    position: 'absolute',
    top: '50%',
    zIndex: 1,
    borderRadius: '100%',
    cursor: disabled ? 'default' : 'pointer',
    background: theme.palette.common.white,
    opacity: disabled ? 0.3 : 1,
    boxShadow: disabled ? theme.shadows[0] : theme.shadows[2],
    ...(prev
      ? {
          left: 0,
          transform: 'translate(-50%,-50%)'
        }
      : {}),
    ...(next
      ? {
          right: 0,
          transform: 'translate(50%,-50%)'
        }
      : {}),
    svg: {
      cursor: disabled ? 'default' : 'pointer',
      display: 'block'
    }
  })
);

const QrCodeMask = styled(Stack)(() => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  left: 0,
  top: 0,
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1,
  svg: { marginBottom: '16px', width: 56, height: 56 }
}));

const lightTheme = createTheme('light');

const LoginProvider: FC<PropsWithChildren> = (props) => {
  const store = useStore();
  const { handleLogin } = useLogin();
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const [isBeginning, setIsBeginning] = useState(false);
  const [isEnd, setIsEnd] = useState(false);
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);
  const [vipList, setVipList] = useState<VipItem[]>([]);

  const handleShowVip = async () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const initList = async () => {
    const res = await getVipList();
    if (res.statusCode === 0) {
      setVipList(res.data);
      setSelectedId(res.data[0].id);
    }
  };

  const handleSlideChange = (swiper: SwiperClass) => {
    setTimeout(() => {
      if (!swiper || swiper.destroyed) {
        setSwiper(null);
        setIsBeginning(true);
        setIsEnd(true);
      } else {
        setSwiper(swiper);
        setIsBeginning(swiper.isBeginning);
        setIsEnd(swiper.isEnd);
      }
    }, 50);
  };

  useEffect(() => {
    if (device.isWeChat) {
      initWechat();
    }
    initList();
  }, []);

  return (
    <VipContext.Provider value={{ handleShowVip }}>
      {props.children}
      <ThemeProvider theme={lightTheme}>
        <Modal
          onClose={handleClose}
          open={open}
          sx={(theme) => ({ display: 'flex', justifyContent: 'center', alignItems: 'center', color: `rgb(140, 86, 11)` })}
        >
          <Box
            sx={{
              width: '80%',
              maxWidth: 718,
              background: 'linear-gradient(120deg, #fff6d9, #fff9ef 50%, #fff9ef) #fff9f1',
              borderRadius: 3,
              outline: 'none'
            }}
          >
            <Stack direction="row" sx={{ justifyContent: 'space-between', alignItems: 'center', mx: 2, my: 1 }}>
              {!store.user.userInfo ? (
                <Stack
                  direction="row"
                  sx={{ alignItems: 'center', userSelect: 'none', cursor: 'pointer' }}
                  spacing={1}
                  onClick={handleLogin}
                >
                  <Avatar sx={{ width: 32, height: 32 }}>
                    <PersonIcon />
                  </Avatar>
                  <Box>
                    <Typography variant="subtitle2">请登录</Typography>
                    <Typography variant="caption" component="p" sx={{ opacity: 0.8 }}>
                      新用户登录，最多享14天免费VIP
                    </Typography>
                  </Box>
                </Stack>
              ) : (
                <Stack direction="row" sx={{ alignItems: 'center', userSelect: 'none' }} spacing={1}>
                  <Avatar sx={{ width: 32, height: 32 }} src={store.user.userInfo.avatar}>
                    <PersonIcon />
                  </Avatar>
                  <Box>
                    <Typography variant="subtitle2">{store.user.userInfo.name}</Typography>
                    <Typography variant="caption" component="p" sx={{ opacity: 0.8 }}>
                      {store.user.userInfo.is_vip
                        ? `VIP 到期时间：${dateFormat(store.user.userInfo.vip_expire_time)}`
                        : `您的VIP已于 ${dateFormat(store.user.userInfo.vip_expire_time)} 到期`}
                    </Typography>
                  </Box>
                </Stack>
              )}
              <Stack direction="row">
                <IconButton
                  aria-label="close"
                  size="small"
                  onClick={handleClose}
                  sx={(theme) => ({
                    color: theme.palette.grey[500]
                  })}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              </Stack>
            </Stack>
            <Stack direction="row" sx={{ p: 2, alignItems: 'center' }}>
              <Paper elevation={0} sx={{ p: 2, width: 0, flex: 1 }}>
                <Box sx={{ position: 'relative' }}>
                  <Swiper spaceBetween={16} slidesPerView={3} onSwiper={handleSlideChange} onSlideChange={handleSlideChange}>
                    {vipList.map((item) => (
                      <SwiperSlide key={item.id}>
                        <Stack
                          sx={{
                            bgcolor: selectedId === item.id ? '#f0cc993d' : 'rgb(247, 247, 247)',
                            alignItems: 'center',
                            borderRadius: 2,
                            justifyContent: 'center',
                            height: '188px',
                            border: `1px solid ${selectedId === item.id ? '#f4a629' : 'transparent'}`,
                            cursor: 'default',
                            userSelect: 'none'
                          }}
                          spacing={2}
                          onClick={() => setSelectedId(item.id)}
                        >
                          <Typography component="div" variant="body2">
                            {item.name}
                          </Typography>
                          <Typography component="div" fontSize={32} fontWeight={900} color={'rgb(140, 86, 11)'}>
                            &yen;{item.amount / 100}
                          </Typography>
                        </Stack>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  <SwiperBtn ownerState={{ prev: true, disabled: isBeginning }} onClick={() => swiper?.slidePrev()}>
                    <KeyboardArrowLeftIcon />
                  </SwiperBtn>
                  <SwiperBtn ownerState={{ next: true, disabled: isEnd }} onClick={() => swiper?.slideNext()}>
                    <KeyboardArrowRightIcon />
                  </SwiperBtn>
                </Box>
              </Paper>
              <Box sx={{ pl: 5, pr: 3, py: 2 }}>
                <Box
                  sx={{
                    width: 160,
                    height: 160,
                    userSelect: 'none',
                    overflow: 'hidden',
                    position: 'relative',
                    border: `1px solid rgba(140, 86, 11, 0.25)`,
                    borderRadius: 2,
                    bgcolor: 'white',
                    img: {
                      width: '100%',
                      height: '100%',
                      display: 'block'
                    }
                  }}
                >
                  <QrCode2Icon
                    sx={{
                      width: '110%',
                      height: '110%',
                      display: 'block',
                      position: 'relative',
                      left: '-5%',
                      top: '-5%',
                      zIndex: 0,
                      opacity: 0.03
                    }}
                  />
                  <QrCodeMask>
                    <CircularProgress sx={{ svg: { m: 0, width: 'auto', height: 'auto' } }} />
                  </QrCodeMask>
                </Box>
                <Stack direction="row" spacing={0.5} sx={{ alignItems: 'center', justifyContent: 'center', mt: 1, userSelect: 'none' }}>
                  <WeChatPayIcon fontSize="small" />
                  <Typography variant="caption">请使用微信扫码完成支付</Typography>
                </Stack>
              </Box>
            </Stack>
          </Box>
        </Modal>
      </ThemeProvider>
    </VipContext.Provider>
  );
};

export default observer(LoginProvider);
