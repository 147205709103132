import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function WeChatPayIcon(props: Omit<SvgIconProps, 'viewBox'>) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        d="M9.3 14.3c-.1 0-.2.1-.3.1-.3 0-.5-.1-.6-.4v-.1l-1.8-4v-.1c0-.2.1-.3.3-.3.1 0 .1 0 .2.1l2.2 1.5c.2.1.3.2.6.2.1 0 .2 0 .3-.1l10.1-4.5c-1.8-2.1-4.8-3.5-8.2-3.5-5.5 0-10 3.8-10 8.4 0 2.5 1.3 4.8 3.5 6.3.2.1.3.3.3.5v.2c-.2.6-.4 1.7-.4 1.7v.3c0 .2.1.3.3.3.1 0 .1 0 .2-.1l2.2-1.3c.2-.1.3-.2.5-.2H9c1 .3 2.1.5 3.3.5 5.5 0 10-3.8 10-8.4 0-1.4-.4-2.7-1.1-3.9L9.3 14.3z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#22ac38"
      />
    </SvgIcon>
  );
}

export default WeChatPayIcon;
