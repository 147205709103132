import { createContext } from 'react';

import theme from 'theme';

export interface ReaderContextStore {
  book: BookDetail | null;
  chapter: BookChapterDetail | null;
  fontSize: number;
  fontFamily: string;
  setFontSize: (fontSize: number) => void;
  setFontFamily: (fontFamily: string) => void;
  setStar: (bookId: number, isStar: 0 | 1) => Promise<void>;
}

const ReaderContext = createContext<ReaderContextStore>({
  book: null,
  chapter: null,
  fontSize: 18,
  fontFamily: theme.typography.fontFamily || '',
  setFontSize(fontSize) {
    this.fontSize = fontSize;
  },
  setFontFamily(fontFamily) {
    this.fontFamily = fontFamily;
  },
  async setStar(bookId: number, isStar: 0 | 1) {}
});

export default ReaderContext;
