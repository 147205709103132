import merge from 'lodash/merge';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function LoginComputer(props: Omit<SvgIconProps, 'viewBox'>) {
  const { sx, ...other } = props;
  return (
    <SvgIcon {...other} viewBox="0 0 422.05 324.78" sx={merge({ width: 210, height: 162 }, sx)}>
      <path
        d="M404.61 0H17.44C7.82 0 0 7.82 0 17.44v232.07c0 9.62 7.82 17.44 17.44 17.44h156.05v35.36c0 .13-.84 12.85-38.67 12.85h-8.91v9.62h170v-9.91h-11.42c-9.98-.27-35.87-3.04-35.87-12.55v-35.38h155.99c9.62 0 17.44-7.82 17.44-17.44V17.44c0-9.62-7.82-17.44-17.44-17.44zM17.44 2h387.17c8.51 0 15.44 6.93 15.44 15.44v210.94H2V17.44C2 8.93 8.93 2 17.44 2zm248.41 312.79c9.19 1.81 18.5 2.08 18.62 2.08h9.45v5.91H127.91v-5.62h6.91c40.13 0 40.67-14.23 40.67-14.84v-35.38h71.13v35.38c0 5.76 6.47 9.95 19.23 12.47zm138.76-49.85H17.44C8.93 264.94 2 258.01 2 249.5v-19.12h418.05v19.12c0 8.51-6.93 15.44-15.44 15.44z"
        fill="#b1b1b1"
      />
      <path
        d="M183.54 66.98h54.68c11.32 0 20.49 9.18 20.49 20.49v54.68c0 11.32-9.18 20.49-20.49 20.49h-54.68c-11.32 0-20.49-9.18-20.49-20.49V87.47c0-11.32 9.18-20.49 20.49-20.49z"
        fill="#1890f8"
      />
      <path
        d="M230.67 91.29c.82.31 1.72 1.07 3.93 3.38 4.05 4.22 5.69 5.52 10.91 8.66 1.37.83 2.77 1.72 3.08 1.97 1.89 1.53 2.11 3.34.61 4.95-.57.61-1.05.85-1.68.84-.89 0-1.38-.33-2.96-1.93-1.83-1.86-2.4-2.23-3.5-2.35-1.27-.13-2.68.67-4.04 2.29-.34.4-2.91 3.91-5.73 7.82s-5.86 8.08-6.75 9.27c-3.14 4.2-6.1 7.76-7.61 9.13-2.22 2.02-4.77 3.21-8.11 3.81-.46.07-1.47.12-2.94.12-2.08 0-2.33-.03-3.44-.26-4.21-.9-8.23-3.2-11.85-6.79-3.57-3.54-5.62-7.52-6.17-11.99-.15-1.29-.06-3.77.19-4.94.8-3.74 3.21-7.45 6.13-9.42 1.96-1.33 4.06-1.96 6.48-1.96 2.86 0 5.28 1.04 7.47 3.2 1.18 1.17 1.95 2.42 2.36 3.86.31 1.07.3 2.7 0 3.68-.73 2.34-2.4 3.87-4.59 4.21-2.51.38-4.72-1.74-4.57-4.4.08-1.48.97-2.53 2.3-2.76.21-.04.39-.09.39-.13 0-.1-.5-.78-.71-.96-.31-.27-1.02-.54-1.6-.6-1.44-.15-3.65.88-4.89 2.27-1.89 2.13-2.79 4.86-2.52 7.57.39 3.8 2.41 7.12 5.99 9.81 5.81 4.37 11.02 4.38 15.72.02 1.55-1.44 4.33-4.77 7.23-8.68 1.45-1.95 10.9-15.01 11.5-15.9.46-.66 1.12-2.09 1.29-2.78.22-.88.14-1.78-.22-2.56-.47-1-1.24-1.66-3.21-2.77-1.72-.96-2.27-1.69-2.26-2.99 0-.87.22-1.4.85-2.06.86-.92 1.64-1.09 2.91-.63z"
        fill="#f6f15f"
      />
      <path
        d="M199.81 90.1c2.38.28 4.35.78 6.34 1.63 4.5 1.91 8.05 5.37 10.03 9.78 1.82 4.05 2.31 8.76 1.36 13.16-.37 1.78-1.48 4.35-2.18 5.12-.21.22-.64.55-.95.72-.5.26-.64.3-1.07.26-.63-.06-1.07-.36-1.45-1.04-.43-.75-.39-1.33.23-3.75 1.19-4.58 1.4-6.8.9-9.27-.96-4.7-3.92-8.17-8.61-10.09-1.17-.48-3.07-.99-4.38-1.17-1.29-.19-4.45-.16-5.79.05-6.03.92-11.56 4.11-14.7 8.47-.76 1.06-1.74 2.98-2.12 4.18-.87 2.72-1.02 5.62-.41 8.61.46 2.32 1.45 4.68 2.58 6.18.71.93 2.17 2.32 3.25 3.07 1.35.94 1.81 1.35 2.15 1.87.72 1.12.37 2.69-.73 3.34-.61.35-1.23.33-2.22-.08-2.52-1.05-5.66-4.19-7.49-7.48-1.09-1.97-2.01-5.02-2.41-8.06-.19-1.46-.21-4.34-.05-5.7.59-4.69 2.47-8.63 5.7-11.95 1.92-1.95 4.01-3.48 6.55-4.77 3.45-1.74 6.92-2.78 10.56-3.16.92-.09 3.81-.05 4.91.07z"
        fill="#ddeefe"
      />
    </SvgIcon>
  );
}

export default LoginComputer;
