import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import MuiLink from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

function TitleBar(props: {
  category?: {
    id: number;
    parent_id: number;
    slug: string;
    name: string;
    children: {
      id: number;
      parent_id: number;
      slug: string;
      name: string;
      type: number;
      is_fiction: number;
    }[];
  };
  slug?: string;
}) {
  const list = useMemo(() => {
    const arr =
      props.category?.children.map((item) => {
        return {
          slug: item.slug,
          name: item.name,
          uri: `/category/${item.slug}`
        };
      }) || [];

    if (arr.length && props.category) {
      arr.unshift({
        slug: props.category.slug,
        name: '全部',
        uri: `/category/${props.category.slug}`
      });
    }
    return arr;
  }, [props.category]);

  return (
    <Box sx={{ pl: { xs: 0, md: 9.5 }, py: 2.5, borderBottom: (theme) => `0.5px solid ${theme.palette.divider}` }}>
      {!!props.category && (
        <Typography variant="h5" component="h2" fontFamily="SourceHanSerifCN" fontWeight={700}>
          {props.category.name}
        </Typography>
      )}
      {!!list.length && (
        <Stack
          direction="row"
          rowGap={2}
          columnGap={8}
          useFlexGap
          sx={{ flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'flex-start', mt: 2.5 }}
        >
          {list.map((children) => (
            <MuiLink
              component={Link}
              key={children.slug}
              to={children.uri}
              variant="body1"
              underline="none"
              color={props.slug === children.slug ? 'primary' : 'textSecondary'}
              sx={{ fontWeight: 'bold' }}
            >
              {children.name}
            </MuiLink>
          ))}
        </Stack>
      )}
    </Box>
  );
}

export default TitleBar;
