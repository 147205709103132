import ReaderContext from 'containers/Reader/ReaderContext';
import { observer } from 'mobx-react-lite';
import { FC, useContext } from 'react';
import { Link } from 'react-router-dom';

import { Avatar } from '@mui/material';
import Divider from '@mui/material/Divider';
import MuiLink from '@mui/material/Link';
import Stack from '@mui/material/Stack';

import CunJinIcon from 'components/icons/CunJinIcon';
import useLogin from 'hooks/useLogin';
import useStore from 'hooks/useStore';

const Header: FC = () => {
  const store = useStore();
  const { book } = useContext(ReaderContext);
  const { handleLogin, handleOpenApp } = useLogin();

  return (
    <>
      <Stack
        id="reader-header"
        component="header"
        direction="row"
        sx={(theme) => ({
          position: 'sticky',
          top: 0,
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 2,
          borderBottom: `0.5px solid ${theme.palette.divider}`,
          bgcolor: theme.palette.background.card
        })}
      >
        <Stack
          direction="row"
          spacing={1.5}
          sx={{
            alignItems: 'center',
            flex: 1,
            width: 0
          }}
        >
          <Link to="/" title="首页">
            <CunJinIcon sx={{ w: 20, h: 20, verticalAlign: 'top' }} />
          </Link>
          {!!book && (
            <MuiLink
              component={Link}
              title={book.title}
              to={`/book/${book.id}`}
              underline="none"
              whiteSpace="nowrap"
              color="textSecondary"
              sx={(theme) => ({
                '&:hover': { color: theme.palette.text.primary },
                flex: 1,
                width: 0,
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              })}
              variant="body1"
            >
              {book.title}
            </MuiLink>
          )}
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          sx={{
            alignItems: 'center'
          }}
          divider={<Divider orientation="vertical" flexItem sx={{ height: '1rem', alignSelf: 'center' }} />}
        >
          <MuiLink
            component={Link}
            title="首页"
            to="/"
            underline="none"
            whiteSpace="nowrap"
            color="textSecondary"
            sx={(theme) => ({ '&:hover': { color: theme.palette.text.primary } })}
            variant="body2"
          >
            首页
          </MuiLink>
          <MuiLink
            component="button"
            title="手机版"
            underline="none"
            whiteSpace="nowrap"
            color="textSecondary"
            sx={(theme) => ({ '&:hover': { color: theme.palette.text.primary } })}
            variant="body2"
            onClick={handleOpenApp}
          >
            手机版
          </MuiLink>
          {!store.user.isLogin && (
            <MuiLink
              component="button"
              title="登录"
              underline="none"
              whiteSpace="nowrap"
              color="textSecondary"
              sx={(theme) => ({ '&:hover': { color: theme.palette.text.primary } })}
              variant="body2"
              onClick={handleLogin}
            >
              登录
            </MuiLink>
          )}
          {!!store.user.isLogin && !!store.user.userInfo && (
            <Avatar sx={{ width: 24, height: 24 }} src={store.user.userInfo.avatar}>
              {store.user.userInfo.name}
            </Avatar>
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default observer(Header);
