import { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';

const NodeContent: FC<{ node: BookChapterNode }> = ({ node }) => {
  if (node.ext?.format === 'markdown') {
    return (
      <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
        {node.content}
      </ReactMarkdown>
    );
  }

  return (
    <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
      {node.content}
    </ReactMarkdown>
  );
};

export default NodeContent;
